import React from "react";
import { useQuery } from '@apollo/react-hooks';
import { Grid, CircularProgress } from '@material-ui/core'
import DescriptionIcon from '@material-ui/icons/Description';
import ErrorIcon from '@material-ui/icons/Error';

import { useParams} from "../shared/utilities"
import { GET_ARTICLE } from "../shared/queries"

import { AuthorList } from "../shared/components/author"
import { ArticleSubheader, ArticleList } from "../shared/components/article"
import { Result } from "../shared/components/result"


function ArticleFetch(props) {
  const { loading, error, data } = useQuery(GET_ARTICLE, { variables: { doi: props.doi } });
  
  if (loading) return null;
  if (error) return <p>Error</p>;

  var article = data.Article[0]
  if (article == null) return null;
  
  return (
    <Result
      elevation={0}
      avatar={article.retracted ? ErrorIcon : DescriptionIcon}
      title={article.title}
      href={"article?doi=" + article.doi}
      subheader={<ArticleSubheader article={article}/>}
    />
  )
}


function Article() {
  let doi = useParams().get("doi")
  const { loading, error, data } = useQuery(GET_ARTICLE, { variables: { doi } });

  if (loading) return (
    <Grid container justify="center" xs={12} children={<CircularProgress/>}/>
  );
  if (error) return <p>Error</p>;

  var article = data.Article[0]
  document.title = "Retractions: " + article.title

  return (
    <Grid container spacing={1}>
      <Grid
        item xs={12}
        children={
          <Result
            about
            elevation={0}
            avatar={article.retracted ? ErrorIcon : DescriptionIcon}
            title={article.title}
            subheader={<ArticleSubheader article={article}/>}
          />
        }
      />
      <AuthorList doi={article.doi} total={article.authors.length}/>
      <ArticleList doi={article.doi} total={article.reference_count} type="referenced"/>
      <ArticleList doi={article.doi} total={article.citation_count} type="citations"/>
    </Grid>
  );
}

export default Article;
export { ArticleFetch };