import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Toolbar, Container } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import Landing from './pages/landing';
import Search from './pages/search'
import Article from './pages/article'
import Author from './pages/author'
import Home from './pages/home'
import Publication from './pages/publication'
import Screening from './pages/screen'
import Navigation, { LandingPageNavigation } from './shared/components/navigation'
import { Footer } from './shared/components/footer'
import PrivateRoute from "./shared/components/privateRoute";


function App() {
  const auth_nav = (<React.Fragment><Navigation/><Toolbar/></React.Fragment>)
  const land_nav = (<React.Fragment><LandingPageNavigation/><Toolbar/></React.Fragment>)

  return (
    <React.Fragment>
      <Box minHeight="calc(100vh - 60px)" m={1}>
        <BrowserRouter forceRefresh={true}>
          <Container>
            {["/home", "/search", "/article", "/publication", "/author", "/screen"].includes(window.location.pathname) ? auth_nav : land_nav}
            <Switch>
              <Route exact path="/" component={Landing}/>
              <PrivateRoute path="/home" component={Home}/>
              <PrivateRoute path="/search" component={Search}/>
              <PrivateRoute exact path="/article" component={Article}/>
              <PrivateRoute exact path="/author" component={Author}/>
              <PrivateRoute exact path="/publication" component={Publication}/>
              <PrivateRoute exact path="/screen" component={Screening}/>
              <Route exact path={process.env.PUBLIC_URL + "privacy.html"} target="_blank"/>
              <Route exact path={process.env.PUBLIC_URL + "terms.html"} target="_blank"/>
              <Route component={() => (<Redirect to="/"/>)} />
            </Switch>
          </Container>
        </BrowserRouter>
      </Box>
      <Footer/>
    </React.Fragment>
);
}

export default App;