import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_AUTHOR, GET_AUTHOR_LIST } from "../queries"
import { Grid, Typography, Button, CircularProgress } from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person';
import { Result } from './result'
import { Chips } from './chips'


function Author(props) {
  // Split author prop into name and uri
  const [ name, uri ] = props.author.split('|')

  // Get data from neo
  const { loading, error, data } = useQuery(GET_AUTHOR, { variables: { uri } })

  if (loading) return null;
  if (error) return <p>Error</p>;

  // Alias to author hit
  var author = data.Author[0]
    
  return (
    <Result
      elevation={0}
      avatar={PersonIcon}
      title={name}
      href={"author?idx=" + uri}
      subheader={<Chips key={uri} data={[
        {"err": true, "n": author.retracted, "suffix": " Retractions"},
        {"err": false, "n": author.published, "suffix": " Articles"}
      ]}/>}
    />
  )
}


function AuthorList(props) {
  const { loading, error, data, fetchMore } = useQuery(GET_AUTHOR_LIST, { variables: { doi: props.doi }})

  function handleClick() {
    fetchMore({
      variables: { offset: data.Article[0].authored_by.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          Article: Array({
            ...prev.Article[0],
            authored_by: [...prev.Article[0].authored_by, ...fetchMoreResult.Article[0].authored_by]
          })
        })
      }
    })
  }

  if (loading) return (
    <React.Fragment>
      <Grid item xs={12} children={<Typography variant="h5" children="Authors"/>}/>
      <Grid container justify="center" xs={12} children={<CircularProgress/>}/>
    </React.Fragment>
  );
  if (error) return <p>Error</p>;

  return (
    <React.Fragment>
      <Grid item xs={12} children={<Typography variant="h5" children="Authors"/>}/>
      {data.Article[0].authored_by.map(author => (
        <Result
          elevation={0}
          avatar={PersonIcon}
          title={author.name}
          href={"author?idx=" + author.uri}
          subheader={<Chips key={author.uri} data={[
            {"err": true, "n": author.retracted, "suffix": " Retractions"},
            {"err": false, "n": author.published, "suffix": " Articles"}
          ]}/>}
        />
      ))}
      <Grid item xs={12} children={
        <Button
          children={"Show More"}
          onClick={handleClick}
          disabled={data.Article[0].authored_by.length >= (props.total || 999999)}
        />
      }/>
    </React.Fragment>
  )
}

export { Author, AuthorList };