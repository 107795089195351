import React from 'react';
import { Container, Grid, Typography, Link } from '@material-ui/core';

  
  function Footer() {
    return (
      <Container>
        <Grid container m={2} spacing={1} alignContent="center" justify="center">
          <Grid item>
            <Link href="/terms_of_service.txt" target="_blank" color="textSecondary" children={
              <Typography variant="caption" children="Terms of Service"/>
            }/>
          </Grid>
          <Grid item>
            <Link href="/privacy_policy.txt" target="_blank" color="textSecondary" children={
              <Typography variant="caption" children="Privacy Policy"/>
            }/>
          </Grid>
          <Grid item>
            <Link href="mailto:retractions.org@gmail.com" target="_blank" color="textSecondary" children={
              <Typography variant="caption" children="Contact Us"/>
            }/>
          </Grid>
        </Grid>
      </Container>
    );
  }

  export { Footer }