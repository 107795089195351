import { useLocation } from "react-router-dom";
import { createBrowserHistory } from "history";


export default createBrowserHistory();

function useParams() {
    return new URLSearchParams(useLocation().search);
}

export { useParams };
  