import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import { Avatar, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginRight: theme.spacing(0.75),
      marginBottom: theme.spacing(0.75),
    },
  },
}));

function Chips(props) {
  const classes = useStyles();

  return (
    <span className={classes.root}>
      {props.data.map((chip) => (
        chip.n > 0 ? 
        <Chip key={`${props.key}-${chip.n}-${chip.suffix}`}
          avatar={chip.err ? <Avatar><ErrorIcon/></Avatar>: null}
          label={chip.n + chip.suffix}
          size="small"
        /> :  null
      ))}
    </span>
    )
}

export { Chips };