import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Grid, Typography, Button, CircularProgress } from "@material-ui/core"
import PersonIcon from '@material-ui/icons/Person';
import ErrorIcon from '@material-ui/icons/Error';
import DescriptionIcon from '@material-ui/icons/Description';

import { useParams } from "../shared/utilities"
import { GET_AUTHOR } from "../shared/queries"

import { ArticleSubheader } from "../shared/components/article"
import { Result } from "../shared/components/result"
import { Chips } from '../shared/components/chips'


function Author() {
  let uri = useParams().get("idx");

  const { loading, error, data, fetchMore } = useQuery(GET_AUTHOR, { variables: { uri } });

  function handleClick() {
    fetchMore({
      variables: { offset: data.Author[0].authored.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        var test = Object.assign({}, prev, {
          Author: Array({
              ...prev.Author[0],
              authored: [...prev.Author[0].authored, ...fetchMoreResult.Author[0].authored]
            })
          })
        return test
      }
    })
  }
  if (loading) return (
    <Grid container justify="center" xs={12} children={<CircularProgress/>}/>
  );
  if (error) return <p>Error</p>;

  var author = data.Author[0]
  document.title = "Retractions: " + author.name

  let chipsData = [
    {"err": true, "n": author.retracted, "suffix": " Retracted"},
    {"err": false, "n": author.reference_retractions, "suffix": " Retracted References"},
    {"err": false, "n": author.citation_retractions, "suffix": " Retracted Citations"}
  ]

  return (
    <Grid container spacing={1}>
      <Grid 
        item xs={12}
        children={
          <Result
            about
            elevation={0}
            avatar={PersonIcon}
            title={author.name}
            subheader={
              <React.Fragment>
                <Typography 
                  variant="subtitle2"
                  children={author.aliases ? "Aliases: " + author.aliases.join('; ') : null}
                />
                <Chips data={chipsData}/>
              </React.Fragment>
            }
          />
        }
      />
      <Grid item xs={12} children={<Typography variant="h5" children="Authored"/>}/>
      {data.Author[0].authored.map(article => (
        <Grid item xs={12}>
          <Result
            key={article.doi}
            elevation={0}
            avatar={article.retracted ? ErrorIcon : DescriptionIcon}
            title={article.title}
            href={"article?doi=" + article.doi}
            subheader={<ArticleSubheader key={article.doi} article={article}/>}
          />
        </Grid>
      ))}
      <Grid item xs={12} children={
        <Button
          disabled={data.Author[0].published <= data.Author[0].authored.length}
          children={"Show More"}
          onClick={handleClick}
        />
      }/>
    </Grid>
  )
}

export default Author;