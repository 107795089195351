import React, { useState } from "react";
import TextField from '@material-ui/core/TextField';
import { Grid, Button } from "@material-ui/core"
import { ArticleFetch } from "./article"


function Screening() {
  const [dois, setDois] = useState([])
  const [tmp, setTmp] = useState("")

  function handleSubmit(event) {
    setDois([...new Set(tmp.split('\n').map(doi => doi.trim().toLowerCase()).filter(doi => doi.length > 0))])
    event.preventDefault();
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <form onSubmit={(e) => handleSubmit(e)}>
          <TextField
            fullWidth
            multiline
            autoFocus
            margin="normal"
            label="DOIs"
            placeholder={"10.1016/j.ijantimicag.2020.105949\n10.1016/j.vaccine.2015.12.067"}
            helperText="Copy+paste a list of line delimited DOIs."
            defaultValue={tmp}
            variant="outlined"
            onChange={(e) => setTmp(e.target.value)}
          />
          <Button type="submit" children={"Search"}/>
        </form>
      </Grid>
      {/* {dois.length > 0 ? <p>`${document.querySelectorAll('[class*="MuiCard-root"]').length}/${dois.length} found`</p>: null} */}
      {dois.map(doi => (<Grid item children={<ArticleFetch doi={doi}/>}/>))}
    </Grid>
  )
}

export default Screening;