import gql from 'graphql-tag';


const ARTICLE_FRAGMENT = gql`
fragment ArticleFragment on Article {
  doi
  title
  year
  retracted
  source_title
  source_uri
  authors
  citation_count
  reference_count
  citation_retractions
  reference_retractions
  sorting_value
}
`

const AUTHOR_FRAGMENT = gql`
fragment AuthorParts on Author {
  name
  uri
  aliases
  published
  retracted
  citations
  reference_retractions
  citation_retractions
  sorting_value
}
`

const PUBLICATION_FRAGMENT = gql`
fragment PublicationParts on Publication {
  title
  uri
  aliases
  issn
  isbn
  published
  retractions
  sorting_value
}
`

const SEARCH_ARTICLES = gql`
query publicationList($q: String, $retracted: Boolean, $offset: Int) {
  Article(filter: {search_contains: $q, retracted: $retracted}, first: 20, offset: $offset, orderBy: sorting_value_desc) {
    ...ArticleFragment
  }
}
${ARTICLE_FRAGMENT}
`

const SEARCH_AUTHORS = gql`
query authorSearch($q: String, $offset: Int) {
  Author(filter: {search_contains: $q}, first: 20, offset: $offset, orderBy:sorting_value_desc) {
    ...AuthorParts
  }
}
${AUTHOR_FRAGMENT}
`

const SEARCH_PUBLICATIONS = gql`
query publicationSearch($q: String, $offset: Int) {
  Publication(filter: {search_contains: $q}, first: 20, offset: $offset, orderBy:sorting_value_desc) {
    ...PublicationParts
  }
}
${PUBLICATION_FRAGMENT}
`

const GET_ARTICLE = gql`
query publication($doi: String!) {
  Article(doi: $doi) {
    ...ArticleFragment
    reference
    citation
  }
}
${ARTICLE_FRAGMENT}
`

const GET_AUTHOR = gql`
query author($uri: String!, $first: Int = 10, $offset: Int = 0) {
  Author(uri: $uri) {
    name
    aliases
    published
    retracted
    citations
    reference_retractions
    citation_retractions
    authored(first: $first, offset: $offset, orderBy: sorting_value_desc) {
      ...ArticleFragment
    }
  }
}
${ARTICLE_FRAGMENT}
`

const GET_AUTHOR_LIST = gql`
query authors($doi: String!, $first: Int = 12, $offset: Int = 0) {
  Article(doi: $doi) {
    authored_by(first: $first, offset: $offset, orderBy: sorting_value_desc) {
      ...AuthorParts
    }
  }
}
${AUTHOR_FRAGMENT}
`

const GET_REFERENCE_LIST = gql`
query references($doi: String!, $first: Int = 5, $offset: Int = 0) {
  Article(doi: $doi) {
    referenced(first: $first, offset: $offset, orderBy: sorting_value_desc) {
      ...ArticleFragment
    }
  }
}
${ARTICLE_FRAGMENT}
`

const GET_CITATION_LIST = gql`
query citations($doi: String!, $first: Int = 5, $offset: Int = 0) {
  Article(doi: $doi) {
    citations(first: $first, offset: $offset, orderBy: sorting_value_desc) {
      ...ArticleFragment
    }
  }
}
${ARTICLE_FRAGMENT}
`

const GET_PUBLICATION = gql`
query publication($uri: String!, $first: Int = 10, $offset: Int = 0) {
  Publication(uri: $uri) {
    title
    issn
    isbn
    aliases
    published
    retractions
    articles(first: $first, offset: $offset, orderBy: sorting_value_desc) {
      ...ArticleFragment
    }
  }
}
${ARTICLE_FRAGMENT}
`

const GET_SCREEN = gql`
query screen($doi: String!) {
  Article(doi: $doi) {
    ...ArticleFragment
  }
}
${ARTICLE_FRAGMENT}
`

export { 
  GET_ARTICLE,
  SEARCH_ARTICLES,
  SEARCH_AUTHORS,
  SEARCH_PUBLICATIONS,
  GET_AUTHOR,
  GET_AUTHOR_LIST,
  GET_CITATION_LIST,
  GET_REFERENCE_LIST,
  GET_PUBLICATION,
  GET_SCREEN,
  AUTHOR_FRAGMENT,
};