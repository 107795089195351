import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search'
import AccountCircle from '@material-ui/icons/AccountCircle'
import PersonIcon from '@material-ui/icons/Person';
import DescriptionIcon from '@material-ui/icons/Description';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import MoreIcon from '@material-ui/icons/MoreVert';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import FilterListIcon from '@material-ui/icons/FilterList';
import FeedbackIcon from '@material-ui/icons/Feedback';
import { AppBar, Toolbar, Typography, Link, Select, Menu, MenuItem, Chip, InputBase, IconButton, Paper, Tooltip, Button } from '@material-ui/core';
import { useParams } from '../utilities'
import { useAuth0 } from "../auth0/react-auth0-spa"
import { Link as RouterLink } from 'react-router-dom'


// https://material-ui.com/components/app-bar/#app-bar-with-a-primary-search-field
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    display: 'none',
    '& > *': {marginRight: theme.spacing(0.75)},
    [theme.breakpoints.up('sm')]: {display: 'block'},
  },
  sectionDesktop: {
    marginLeft: 'auto',
    display: 'none',
    [theme.breakpoints.up('md')]: {display: 'flex'}
  },
  sectionMobile: {
    display: 'flex',
    marginLeft: 'auto',
    [theme.breakpoints.up('md')]: {display: 'none'}
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    display: "flex",
    width: "100%",
  },
  inputInput: {
    color: "white",
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create('width'),
    width: '100%',
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  arrowIcon: {color: "white"},
  divider: {
    height: 28,
    margin: 4,
    light: true
  },
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(1),
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    [theme.breakpoints.up("sm")]: {
      width: "350px"
    }
  },
  buttonsRoot: {
    display: "flex",
    marginLeft: 'auto',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  customWidth: {
    maxWidth: 200,
  },
}));


function Search() {
  const classes = useStyles();
  const params = useParams();
  const [q, setQ] = React.useState(params.get("q") || "");
  const [type, setType] = React.useState(params.get("type") || "articles");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl)

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event) => {
    setAnchorEl(null);
  }

  return (
    <Paper 
      elevation={0}
      className={classes.root}
      component="form"
      action="/search?"
    >
      <Tooltip title="Search">
        <IconButton
            type="submit"
            aria-label="search"
            size="small"
            color="inherit"
            children={<SearchIcon style={{color: "white"}}/>}
          />
      </Tooltip>
      <Select style={{display: 'none'}} name="type" value={type}/>
      <InputBase
        placeholder={"Search " + type}
        name="q"
        value={q}
        onChange={e => setQ(e.target.value)}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput
        }}
      />
      {type === "articles" ? <IconButton disabled size="small" children={<DescriptionIcon style={{color: "rgb(256, 256, 256, 0.25)"}}/>}/> : null}
      {type === "authors" ? <IconButton disabled size="small" children={<PersonIcon style={{color: "rgb(256, 256, 256, 0.25)"}}/>}/>  : null}
      {type === "publications" ? <IconButton disabled size="small" children={<MenuBookIcon style={{color: "rgb(256, 256, 256, 0.25)"}}/>}/>  : null}
      <Tooltip title="Filter">
        <IconButton 
          onClick={handleMenuOpen}
          size="small" 
          color="inherit"
          children={<FilterListIcon style={{color: "white"}}/>}
        />
      </Tooltip>
      <Menu
        keepMounted
        id="search-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem dense onClick={() => {setType("articles"); setAnchorEl(null)}}>
          <IconButton color="inherit" children={<DescriptionIcon/>}/>
          <p>Articles</p>
        </MenuItem>
        <MenuItem dense onClick={() => {setType("authors"); setAnchorEl(null)}}>
          <IconButton color="inherit" children={<PersonIcon/>}/>
          <p>Authors</p>
        </MenuItem>
        <MenuItem dense onClick={() => {setType("publications"); setAnchorEl(null)}}>
          <IconButton color="inherit" children={<MenuBookIcon/>}/>
          <p>Publications</p>
        </MenuItem>
      </Menu>
    </Paper>
  )
}


function Navigation() {
  const classes = useStyles();
  const { logout } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const renderMenu = (
    <Menu
      keepMounted
      id="simple-menu"
      anchorEl={anchorEl}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem dense component={Link} 
        target="_blank" 
        href="http://forms.gle/5itWddsHiyQuvcos8"
        color="inherit"
        style={{ textDecoration: 'none' }}
      >
        <IconButton aria-label="feedback" color="inherit" children={<FeedbackIcon/>}/>
        <p>Feedback</p>
      </MenuItem>
      <MenuItem dense onClick={() => logout()}>
        <IconButton color="inherit" children={<AccountCircle/>}/>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  )

  const renderMobileMenu = (
    <Menu
      keepMounted
      id="mobile-menu"
      anchorEl={mobileMoreAnchorEl}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem dense component="a" href="/screen" >
        <IconButton aria-label="screen dois" color="inherit" children={<DoneAllIcon/>}/>
        <p>Screen</p>
      </MenuItem>
      <MenuItem dense component={Link} 
        target="_blank" 
        href="http://forms.gle/5itWddsHiyQuvcos8"
        color="inherit"
        style={{ textDecoration: 'none' }}
      >
        <IconButton aria-label="feedback" color="inherit" children={<FeedbackIcon/>}/>
        <p>Feedback</p>
      </MenuItem>
      <MenuItem dense onClick={() => logout()}>
        <IconButton color="inherit" children={<AccountCircle/>}/>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  )

  return (
    <div className={classes.grow}>
      <AppBar position="absolute" elevation={0}>
        <Toolbar>
          <Typography variant="h6" noWrap className={classes.title}>
            <Link to="/home" href="/home" color="inherit" style={{ textDecoration: 'none' }} component={RouterLink}>
            retractions.org&nbsp;
            <Chip label="beta" size="small" color="secondary"/>
          </Link>
          </Typography>
          <Search/>
          <div className={classes.sectionDesktop}>
            <Tooltip title="Screen">
              <IconButton
                component={RouterLink}
                aria-label="screen dois"
                color="inherit"
                href="/screen"
                to="/screen"
                children={<DoneAllIcon/>}
              />
            </Tooltip>
            <Tooltip title="Account">
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls="simple-menu"
                color="inherit"
                onClick={handleProfileMenuOpen}
                children={<AccountCircle/>}
              />
            </Tooltip>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              edge="end"
              aria-controls="mobile-menu"
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
              children={<MoreIcon/>}
            />
          </div>
          {renderMobileMenu}
          {renderMenu}
        </Toolbar>
      </AppBar>
    </div>
  )
}

function LandingPageNavigation() {
  const classes = useStyles();

  return (
    <div className={classes.grow}>
      <AppBar position="absolute" elevation={0}>
        <Toolbar>
          <Typography variant="h6" noWrap className={classes.title}>
            retractions.org&nbsp;
            <Chip label="beta" size="small" color="secondary"/>
          </Typography>
          <Tooltip title="
            Our public beta has closed. Please contact us via email for 
            additional information."
            classes={{ tooltip: classes.customWidth}}
          >
          <div className={classes.buttonsRoot}>
            <Button
              disabled
              component={RouterLink}
              color="inherit"
              to="/home"
              href="/home"
              children="Login"
            />
            <Button
              component={RouterLink}
              color="inherit"
              variant="outlined"
              to="/home"
              href="/home"
              children="Sign Up"
            />
          </div>
          </Tooltip>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default Navigation;
export { LandingPageNavigation };