import React from 'react';
import { Grid, Paper, Typography, Chip, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import DescriptionIcon from '@material-ui/icons/Description';
import ErrorIcon from '@material-ui/icons/Error';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PersonIcon from '@material-ui/icons/Person';
import { Author } from "../shared/components/author"
import { ArticleFetch } from "./article"


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(0.5),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  spacer: {
    paddingTop: theme.spacing(2)
  }
}));

  
function Home() {
  const classes = useStyles();
  const suggestedAuthors = [
    'Fujii, Yoshitaka|1c80ee987c3f752baab58f95e5e4741f882a1c62a9bb1b2f28aa201ff9a12de3',
    'Boldt, Joachim|f75e6abffee3d4c73e7306268cdf1e4503d8ec1d39a065765c89136a72bad80e',
    'Toyooka, Hidenori|ac6c1b2b14eab799ee899b5eeb355d48ff4dd2cc6b54d8052205c50c3f3336ac',
    'Iwamoto, Jun|31abd49f1910449d30e487ad6c4f2c64fc0a970f6f9709b0c8bce34098f688f0',
    'Suttner, S.|3f113ff58b6a610bdc97cd4809c02f436f71b38e1df1f2f4fdf7fcd4db01acbb',
    'Tanaka, Hiroyoshi|f57724a91d5831a4a354b2fd3f52ed5da14634ab7cbf56b4810b1042d01fcf6c',
    'Nazari, Ali|1065f399ef29599858212adc1e28a739c36e6815e5b4f4d4137b232273413ea9',
    'Saitoh, Yuhji|d5aa28df8e50bb1ede2897b260c44419c8a5b57a72c65cfbe1bf75aeaf2c58a6',
    'Aggarwal, Bharat B.|4e9065662c61ae4edae5de2347e4dab3258c72476f16293ceb58924b30057a86',
    'Stapel, Diederik A.|565d2c3beaf735e4a9f675ca07240199eef1f660f24c46fd2524ae10d6a7f504',
    'Sarkar, Fazlul H.|668088d65eed8eb571a854a0390bec4c303bdae1fff83d9f032e34b8c17ab2c8',
  ]
  const suggestedArticles = [
    '10.1016/j.ijantimicag.2020.105949',
    '10.1016/s0140-6736(97)11096-0',
    '10.1161/circresaha.111.243154',
    '10.1056/nejmoa1200303'
  ]

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper elevation={0} className={classes.root}>
            <Chip className={classes.chip} variant="outlined" avatar={<ErrorIcon/>} size="small" label="Retraction"/>
            <Chip className={classes.chip} variant="outlined" avatar={<DescriptionIcon/>} size="small" label="Article"/>
            <Chip className={classes.chip} variant="outlined" avatar={<PersonIcon/>} size="small" label="Author"/>
            <Chip className={classes.chip} variant="outlined" avatar={<MenuBookIcon/>} size="small" label="Publication"/>
          </Paper>
          <Typography className={classes.root} variant="caption" color="textSecondary" children="Icons used throughout the application"/>
        </Grid>
        <Grid item xs={12} children={<Divider/>}/>
      </Grid>
      <Grid container>
        <Grid item className={classes.spacer} xs={12} children={<Typography variant="h5" children={"Suggested Authors"}/>}/>
        {suggestedAuthors.map(author => (<Grid item children={<Author author={author}/>}/>))}
        <Grid item className={classes.spacer} xs={12} children={<Typography variant="h5" children={"Suggested Articles"}/>}/>
        {suggestedArticles.map(doi => (<Grid item children={<ArticleFetch doi={doi}/>}/>))}
      </Grid>
    </React.Fragment>
  )
}

export default Home