import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ApolloClient from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from '@apollo/react-hooks';
import { Auth0Provider, useAuth0 } from "./shared/auth0/react-auth0-spa";
import history from "./shared/utilities";
import config from "./auth_config.json";


const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const cache = new InMemoryCache();

export const GraphQLProvider = ({ children }) => {
  const { isAuthenticated, getIdTokenClaims } = useAuth0();

  const client = new ApolloClient({
    uri: 'https://api.retractions.org/',
    request: async operation => {
      // Get token or get refreshed token
      const token = isAuthenticated ? await getIdTokenClaims() : null;
      operation.setContext({ headers: { authorization: token ? `Bearer ${token.__raw}` : undefined } });
    },
    cache
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};


ReactDOM.render(
  <Auth0Provider
    domain={config.domain}
    client_id={config.clientId}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
  >
    <GraphQLProvider children={<App/>}/>
  </Auth0Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
