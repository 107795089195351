import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Link, Typography, Grid, CircularProgress } from '@material-ui/core';
import { Chips } from './chips'
import { GET_REFERENCE_LIST, GET_CITATION_LIST } from "../queries"
import { Result } from "./result"
import DescriptionIcon from '@material-ui/icons/Description';
import ErrorIcon from '@material-ui/icons/Error';
import Button from '@material-ui/core/Button';
import { Link as RouterLink } from 'react-router-dom'


function ArticleSubheader(props) {
  return (
    <React.Fragment>
      {props.article.authors ? props.article.authors.map(author => (
        <React.Fragment key={author}>
          <Link
            component={RouterLink}
            key={author}
            color="inherit"
            href={"author?idx=" + author.split('|')[1]}
            to={"author?idx=" + author.split('|')[1]}
            children={author.split('|')[0]}
          />
        </React.Fragment>
      )).reduce((prev, curr) => [...prev, prev.length ? "; " : null, curr], []) : null}
      {props.article.authors ? <br/> : null}
      <Typography variant="subtitle2">
        {props.article.year}
        {props.article.year ? <span> - </span> : null}
        <Link
          component={RouterLink}
          color="inherit"
          href={"publication?idx=" + props.article.source_uri}
          to={"publication?idx=" + props.article.source_uri}
          children={props.article.source_title}
        />
        {props.article.source_title ? <span> - </span> : null}
        <Link
          color="inherit"
          href={"https://doi.org/" + props.article.doi}
          target="_blank"
          children={props.article.doi}
        />
      </Typography>
      <Chips data={[
        {"err": false, "n": props.article.citation_count, "suffix": " Citations"},
        {"err": true, "n": props.article.citation_retractions, "suffix": " Retracted Citations"},
        {"err": false, "n": props.article.reference_count, "suffix": " References"},
        {"err": true, "n": props.article.reference_retractions, "suffix": " Retracted References"},
      ]}/>
    </React.Fragment> 
  )
}


function ArticleList(props) {
  const query = (props.type === "referenced" ? GET_REFERENCE_LIST : GET_CITATION_LIST)
  const { loading, error, data, fetchMore } = useQuery(query, { variables: { doi: props.doi }})

  function handleClick() {
    fetchMore({
      variables: { offset: data.Article[0][props.type].length },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          Article: Array({
            ...prev.Article[0],
            [props.type]: [...prev.Article[0][props.type], ...fetchMoreResult.Article[0][props.type]]
          })
        })
      }
    })
  }

  if (loading) return (
    <React.Fragment>
      <Grid item xs={12} children={
        <Typography
          variant="h5"
          children={props.type === "referenced" ? "References" : "Citations"}
        />
      }/>
      <Grid container justify="center" xs={12} children={<CircularProgress/>}/>
    </React.Fragment>
  );
  if (error) return <p>Error</p>;
  if (data.Article[0][props.type].length === 0) return null;

  return (
    <React.Fragment>
      <Grid item xs={12} children={
        <Typography
          variant="h5"
          children={props.type === "referenced" ? "References" : "Citations"}
        />
      }/>
      {data.Article[0][props.type]? data.Article[0][props.type].map(article => (
        <Grid key={article.doi + "-grid"} item xs={12}>
          <Result
            key={article.doi}
            elevation={0}
            avatar={article.retracted ? ErrorIcon : DescriptionIcon}
            title={article.title}
            href={"article?doi=" + article.doi}
            subheader={<ArticleSubheader article={article}/>}
          />
        </Grid>
      )) : null}
      <Button 
        children={"Show More"} 
        onClick={handleClick}
        disabled={data.Article[0][props.type].length >= (props.total || 999999)}
      />
    </React.Fragment>
  )
}


export { ArticleSubheader, ArticleList };