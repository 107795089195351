import React from "react";
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import DescriptionIcon from '@material-ui/icons/Description';
import ErrorIcon from '@material-ui/icons/Error';
import PersonIcon from '@material-ui/icons/Person';
import MenuBookIcon from '@material-ui/icons/MenuBook';

import { useParams } from "../shared/utilities";
import { SEARCH_ARTICLES, SEARCH_AUTHORS, SEARCH_PUBLICATIONS } from "../shared/queries"

import { ArticleSubheader } from "../shared/components/article"
import { Chips } from '../shared/components/chips'
import { Result } from "../shared/components/result"
import Button from '@material-ui/core/Button';


function SearchArticles(props) {
  const { loading, error, data, fetchMore } = useQuery(SEARCH_ARTICLES, {variables: { q: props.q.toLowerCase() }});

  function handleClick() {
    fetchMore({
      variables: { offset: data.Article.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          Article: [...prev.Article, ...fetchMoreResult.Article]
        });
      }
    })
  }

  if (loading) return (
    <Grid container justify="center" xs={12} children={<CircularProgress/>}/>
  );
  if (error) return <p>Error</p>;
  if (data.Article == null) return null;

  return (
    <React.Fragment>
       {data.Article? data.Article.map(article => (
        <Grid item xs={12}>
          <Result
            elevation={0}
            avatar={article.retracted ? ErrorIcon : DescriptionIcon}
            title={article.title}
            href={"article?doi=" + article.doi}
            subheader={<ArticleSubheader article={article}/>}
          />
        </Grid>
       )) : null}
      <Button children={"Show More"} onClick={handleClick}/>
    </React.Fragment>
  )
}

function SearchAuthors(props) {
  const { loading, error, data, fetchMore } = useQuery(SEARCH_AUTHORS, {variables: { q: props.q.toLowerCase() }});

  function handleClick() {
    fetchMore({
      variables: { offset: data.Author.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          Author: [...prev.Author, ...fetchMoreResult.Author]
        });
      }
    })
  }

  if (loading) return (
    <Grid container justify="center" xs={12} children={<CircularProgress/>}/>
  );
  if (error) return <p>Error</p>;
  if (data.Author == null) return null;

  return (
    <React.Fragment>
       {data.Author? data.Author.map(author => (
        <Grid item xs={12}>
          <Result
            elevation={0}
            avatar={PersonIcon}
            title={author.name}
            href={"author?idx=" + author.uri}
            subheader={
              <React.Fragment>
                <Typography 
                  variant="subtitle2"
                  children={author.aliases ? "Aliases: " + author.aliases.join(', ') : null}
                />
                <Chips data={[
                  {"err": true, "n": author.retracted, "suffix": " Retracted"},
                  {"err": false, "n": author.reference_retractions, "suffix": " Retracted References"},
                  {"err": false, "n": author.citation_retractions, "suffix": " Retracted Citations"}
                ]}/>
              </React.Fragment>
            }
          />
        </Grid>
       )) : null}
      <Grid item xs={12} children={<Button children={"Show More"} onClick={handleClick}/>}/>
    </React.Fragment>
  )
}

function SearchPublications(props) {
  const { loading, error, data, fetchMore } = useQuery(SEARCH_PUBLICATIONS, {variables: { q: props.q.toLowerCase() }});

  function handleClick() {
    fetchMore({
      variables: { offset: data.Publication.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          Publication: [...prev.Publication, ...fetchMoreResult.Publication]
        });
      }
    })
  }

  if (loading) return (
    <Grid container justify="center" xs={12} children={<CircularProgress/>}/>
  );
  if (error) return <p>Error</p>;
  if (data.Publication == null) return null;

  return (
    <React.Fragment>
       {data.Publication? data.Publication.map(publication => (
        <Grid item xs={12}>
          <Result
            elevation={0}
            avatar={MenuBookIcon}
            title={publication.title}
            href={"publication?idx=" + publication.uri}
            subheader={
              <React.Fragment>
                { publication.issn ? <Typography variant="body2">ISSN: {publication.issn.filter(x => x).join(", ")}</Typography> : null }
                { publication.isbn ? <Typography variant="body2">ISBN: {publication.isbn.filter(x => x).join(', ')}</Typography> : null }
                { publication.aliases ? <Typography variant="body2">Aliases: {publication.aliases.join(', ')}</Typography> : null }
                <Chips data={[
                  {"err": true, "n": publication.retractions, "suffix": " Retractions"},
                  {"err": false, "n": publication.published, "suffix": " Published"}
                ]}/>
              </React.Fragment>
            }
          />
        </Grid>
       )) : null}
      <Button children={"Show More"} onClick={handleClick}/>
    </React.Fragment>
  )
}

function Search() {
  const q = useParams().get("q");
  const type = useParams().get("type")
  document.title = "Retractions: " + q

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12} children={<Typography variant="h5" children="Results"/>}/>
        {type === "articles" ? <SearchArticles q={q}/> : null}
        {type === "authors" ? <SearchAuthors q={q}/> : null}
        {type === "publications" ? <SearchPublications q={q}/> : null}
      </Grid>
    </React.Fragment>
  )
}

export default Search;