import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Grid, Typography, Button, CircularProgress } from "@material-ui/core"
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { useParams, } from "../shared/utilities"
import { GET_PUBLICATION } from "../shared/queries"
import DescriptionIcon from '@material-ui/icons/Description';

import ErrorIcon from '@material-ui/icons/Error';
import { ArticleSubheader } from "../shared/components/article"
import { Chips } from "../shared/components/chips"
import { Result } from "../shared/components/result"


function Publication() {
  let uri = useParams().get("idx");
  const { loading, error, data, fetchMore } = useQuery(GET_PUBLICATION, { variables: { uri } });

  function handleClick() {
    fetchMore({
      variables: { offset: data.Publication[0].articles.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          Publication: Array({
            ...prev.Publication[0],
            articles: [...prev.Publication[0].articles, ...fetchMoreResult.Publication[0].articles]
          })
        })
      }
    })
  }

  if (loading) return (
    <Grid container justify="center" xs={12} children={<CircularProgress/>}/>
  );
  if (error) return <p>Error</p>;

  var publication = data.Publication[0]
  document.title = "Retractions: " + publication.title

  let chipsData = [
    {"err": true, "n": publication.retractions, "suffix": " Retractions"},
    {"err": false, "n": publication.published, "suffix": " Published"}
  ]

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Result
            about
            elevation={0}
            avatar={MenuBookIcon}
            title={publication.title}
            subheader={
              <React.Fragment>
                { publication.issn ? <Typography variant="body2">ISSN: {publication.issn.filter(x => x).join(", ")}</Typography> : null }
                { publication.isbn ? <Typography variant="body2">ISBN: {publication.isbn.filter(x => x).join(', ')}</Typography> : null }
                { publication.aliases ? <Typography variant="body2">Aliases: {publication.aliases.join(', ')}</Typography> : null }
                <Chips data={chipsData}/>
              </React.Fragment>
            }
          /> 
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" children={"Published"}/>
        </Grid>
        {publication.articles.map(article => (
          <Grid item xs={12}>
            <Result
              elevation={0}
              avatar={article.retracted ? ErrorIcon : DescriptionIcon}
              title={article.title}
              href={"article?doi=" + article.doi}
              subheader={<ArticleSubheader article={article}/>}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Button 
            disabled={publication.published <= publication.articles.length}
            children={"Show More"} 
            onClick={handleClick}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}


export default Publication;