import React from 'react';
import { Link, Card, CardHeader } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom'

function Result(props) {
  return (
    <Card elevation={ props.elevation }>
      <CardHeader
        style={{paddingBottom: "8px", paddingTop: "8px"}}
        avatar={<props.avatar style={{ "color": "rgba(0, 0, 0, 0.54)", "marginRight": 16 }}/>}
        title={
          <Link 
            component={props.href ? RouterLink : "a"}
            color="inherit" 
            variant={props.about ? "h4" : "body1"}
            underline={props.about ? "none" : "hover"}
            href={props.href}
            to={props.href}
            children={props.title}
          />
        }
        subheader={props.subheader}
      />
    </Card>
  )
}


export { Result };