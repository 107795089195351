import React from "react";
import { Typography, Grid, Card, CardContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import DescriptionIcon from '@material-ui/icons/Description';
import PersonIcon from '@material-ui/icons/Person';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ErrorIcon from '@material-ui/icons/Error';
import TimelineIcon from '@material-ui/icons/Timeline';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import StorageIcon from '@material-ui/icons/Storage';
import PeopleIcon from '@material-ui/icons/People';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import Particles from 'react-particles-js';


const useStyles = makeStyles((theme) => ({
  section: {
    paddingBottom: "64px",
    alignItems: "center",
    zIndex: 2
  },
  sectionCards: {
    paddingBottom: "56px",
    alignItems: "center",
    zIndex: 2
  },
  sectionTop: {
    paddingTop: "64px",
    paddingBottom: "64px",
    alignItems: "center",
    zIndex: 2
  },
  text: {
    textAlign: 'center',
  },
  missingOppositeContent: {
    "&:before": {
      display: "none"
    }
  }
}))


function Numbers(props) {
  const classes = useStyles()
  return (
    <Grid item>
      <Card elevation={0}>
        <CardContent className={classes.text}>
          {props.icon}
          <Typography variant="h3" component="h2">{props.value}</Typography>
          <Typography variant="h6" component="h2">{props.subtitle}</Typography>
        </CardContent>
      </Card>
    </Grid>
  )
}


function Landing() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Particles
        width="100%" 
        height="370px" 
        style={{position: "absolute", top: 0, left: 0, zIndex: -10}} 
        params={{
          background: {color: {value: "#3f51b5"}},
          particles: {
            number: {
              density: {enable: true, value_area: 800},
              value: 30,
            },
            move: {enable: true, speed: 3},
            size: {value: 2},
            opacity: {value: 0.3},
            links: {opacity: 0.3}
          }
      }}/>
      <Grid 
        container 
        style={{height: 298}} 
        justify="center" 
        alignItems="center"
      >
        <Grid item sm={8} alignItems="center">
          <Typography variant="h2" align="center" style={{color: "white"}}>Research retractions</Typography>
          <Typography variant="h6" align="center" style={{color: "white"}}>retractions.org aggregates retractions and connects the dots between authors, publishers, and references.</Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.sectionTop} justify="center">
        <Grid item md={10} justify="center">
          <Typography align="center">
            With over 60 million relationships, our unified interface of linked articles, authors, and publishers enables users to screen their bibliography for any links to retracted articles.
          </Typography>
        </Grid>
      </Grid>
      <Grid container justify="center" className={classes.sectionCards} spacing={2}>
        <Numbers value={"25K"} subtitle={"Retractions"} icon={<ErrorIcon/>}/>
        <Numbers value={"34K"} subtitle={"Publications"} icon={<MenuBookIcon/>}/>
        <Numbers value={"1.6M"} subtitle={"Authors"} icon={<PersonIcon/>}/>
        <Numbers value={"16M"} subtitle={"Articles"} icon={<DescriptionIcon/>}/>
        <Numbers value={"60M"} subtitle={"Relationships"} icon={<TimelineIcon/>}/>
      </Grid>
      <Grid container justify="center">
        <Grid item className={classes.section} md={5} justify="left">
          <Timeline>
            <TimelineItem className={classes.missingOppositeContent}>
              <TimelineSeparator>
                <TimelineDot children={<StorageIcon/>}/>
                <TimelineConnector/>
              </TimelineSeparator>
              <TimelineContent><Typography children="We aggregate retractions from academic databases and publishers."/></TimelineContent>
            </TimelineItem>
            <TimelineItem className={classes.missingOppositeContent}>
              <TimelineSeparator>
                <TimelineDot children={<BubbleChartIcon/>}/>
                <TimelineConnector/>
              </TimelineSeparator>
              <TimelineContent><Typography children="We use open data to link articles through citations and provide article metadata."/></TimelineContent>
            </TimelineItem>
            <TimelineItem className={classes.missingOppositeContent}>
              <TimelineSeparator>
                <TimelineDot children={<PeopleIcon/>}/>
              </TimelineSeparator>
              <TimelineContent><Typography children="We perform entity resolution to connect authors and publishers across articles."/></TimelineContent>
            </TimelineItem>
          </Timeline>
        </Grid>
        <Grid item className={classes.section} md={5}>
          <img src={process.env.PUBLIC_URL + '/imgs/mockup.png'} alt="" style={{width: "100%"}}></img>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Landing;